import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../shared/types/enums";
import { Subject } from 'rxjs';

export default class ReportSaveService {
  static reportSavedSubject = new Subject();

  // static fetchLanguagesByParentIzendaAsOBS = (_tenantId, _parentIzendaDisplayName) => {
  //   return ApiService.getOBS(
  //     API_ENDPOINT.REPORTS,
  //     `/Reports/FetchLanguagesByParentIzenda?tenantId=${_tenantId}&parentIzendaDisplayName=${_parentIzendaDisplayName}`
  //   );
  // };

  // static fetchReportCompanionDetails = (_tenantId, _parentIzendaDisplayName) => {
  //   return ApiService.getOBS(
  //     API_ENDPOINT.REPORTS,
  //     `/Reports/FetchReportCompanionDetails?tenantId=${_tenantId}&parentIzendaDisplayName=${_parentIzendaDisplayName}`
  //   );
  // };

  static fetchReportSaveFileTypes = () => {
    return ApiService.getOBS(
      API_ENDPOINT.REPORTS,
      `/Reports/FetchReportSaveFileTypes`
    );
  };

  // static fetchFiltersByReport = (postObj, headers) => {
  //   return ApiService.setOBS(
  //     "POST",
  //     API_ENDPOINT.IZENDA_CORE,
  //     `/api/report/loadForEdit`,
  //     postObj,
  //     headers
  //   )
  // }

  // static fetchFilterValues = (_tenantId) => {
  //   return ApiService.getOBS(
  //     API_ENDPOINT.REPORTS,
  //     `/Reports/FetchIzendaFilters?tenantId=${_tenantId}`
  //   );
  // };

}
