export const ResultStatus = {
  NOT_LOADED: 0,
  LOADING: 1,
  LOADED: 2,
  SAVING: 3,
  SUCCESS: 4,
  ERROR: 5,
};

export const SaveStatus = {
  NONE: 0,
  START: 1,
  SUCCESS: 2,
  ERROR: -1,
};

export const ALIGNMENT = {
  LEFT: 1,
  CENTER: 2,
  RIGHT: 3,
  JUSTIFY: 4,
}

export const POSITION = {
  TOP_LEFT: 1,
  TOP_CENTER: 2,
  TOP_RIGHT: 3,
  BOTTOM_LEFT: 4,
  BOTTOM_CENTER: 5,
  BOTTOM_RIGHT: 6,
}

export const ENTITY_FIELD_TYPE = {
  NUMERIC: 1,
  TEXT: 2,
  DATE_TIME: 3,
  BOOLEAN: 4,
  LOV: 5,
  DATE: 6,
  DECIMAL: 7,
  HLOV: 8
}

export const API_ENDPOINT = {
  BASE: 0,
  CORE: 1,
  IDENTITY: 2,
  HOME: 3,
  TENANT: 4,
  REPORTS: 5,
  ADDRESS: 6,
  CONFIG: 7,
  // IZENDA_CORE: 8,
  // IZENDA_IDENTITY: 9,
};


export const SELECT_MODE = {
  INVERT: -1,
  NONE: 0,
  ALL: 1,
}

export const ROLE_CELL_TYPE = {
  NONE: 0,
  PARENT: 1,
  CHILD: 2
}

export const CELL_ACTION = {
  NONE: 0,
  EDIT: 1,
  DELETE: 2,
  ACCEPT: 3,
  CANCEL: 4
}

// remove this and replace it with API_ENDPOINT
export const GST_API = {
  Identity: 0,
  Core: 1,
  TenantConfig: 2,
  Reports: 3,
  Address: 4,
};

export const CrudAction = {
  NONE: 0,
  CREATE: 1,
  READ: 2,
  UPDATE: 3,
  DELETE: 4,
  VIEW_DISABLED: 5,
};

export const ReportCategory = {
  COMPLIANCE_TEMPLATES: 1,
  STANDARD_TEMPLATES: 2,
  CHART_TEMPLATES: 3,
  REPORT_LIBRARIES: 4
};

export const ReportLibrary = {
  IN_REVIEW: 1,
  APPROVED: 2
};

export const ReportType = {
  US_GENERAL: "USGeneral",
  US_STATE: "USState",
  US_OWNERSHIP: "USOwnership",
  OUS: "oUS",
  STANDARD: "Standard"
};

export const TenantSelectList = {
  NONE: 0,
  LANGUAGE: 1,
  COUNTRY: 2,
  CURRENCY: 3,
  TIME_ZONE: 4,
  CONTACT_TYPE: 131,
  TRANSACTION_STATUS: 6,
  PROFILE_STATUS: 7,
  AGREEMENT_STATUS: 8
};

export const AffiliatesSelectList = {
  ManufacturerType: 1,
  FederalBusinessType: 2,
  CMSBusinessType: 3,
};

export const ENTITY_TYPE = {
  PROFILE: 1,
  TRANSACTION: 2,
  AGREEMENT: 3,
  DATA: 7,
  REPORTS: 9,
  SALES_REP: 14
}

export const FileDeleteAllow = {
  ALLOW_PROFLE: 1,
  DISALLOW_PROFILE: 2,
  ALLOW_TRANSACTION: 3,
  DISALLOW_TRANSACTION: 4,
  REDIRECT_FROM_DATA_TO_TRANSACTION: 5,
  UNKNOWN: 999,
}

export const FieldTypes = {
  "Numeric": 1,
  "Text": 2,
  //"Date Time": 3,
  "Boolean": 4,
  "LOV": 5,
  "Date": 6,
  "Decimal": 7,
};

export const PROFILE_CATEGORY = {
  INDIVIDUAL: "Individual",
  ORGANIZATION: "Organization"
}

export const TaskLauncher = {
  NONE: 0,
  TASK_LIST: 1,
  PROFILE_EXPANDED_VIEW: 2,
  PROFILE_DETAILS: 3,
  TRANSACTION_EXPANDED_VIEW: 4,
  TRANSACTION_DETAILS: 5,
}

export const ReportableMap = {
  250: "Reportable - Direct",
  251: "Reportable - Aggregate",
}

export const FILTER_TYPE = {
  NUMERIC: 1,
  TEXT: 2,
  DATE: 3,
  LOV: 5
}

export const FILTER_FIELD_TYPE = {
  TEXT: 2,
  LOV: 5,
  DATE: 6,
}

export const HEALTH_INDICATOR = {
  1: "error",
  2: "warning",
  3: "unmatched",
  4: "eligible"
}