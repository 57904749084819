import SessionService from "../../../shared/services/sessionService";
import ApiService from "../../../shared/services/apiService";
import { API_ENDPOINT } from "../../../shared/types/enums";

export default class ReportSettingsService {

    //not using izenda so commenting below method
    // static getchCurrentIzendaTenantId = () => {
    //     const authUserObj = SessionService.getUserModelFromStorage();
    //     const headerParams = {
    //         method: "GET",
    //         headers: {
    //             "access_token": `${authUserObj.sessionToken}`,
    //             "Access-Control-Allow-Origin": "*",
    //             "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    //             "Content-Type": "application/json",
    //         }
    //     };
    //     return ApiService.getOBS(API_ENDPOINT.IZENDA_CORE, `/api/tenant/allTenants`, headerParams);
    // }


    static getReportFilters = (_tenantId, _reportId) => {
        return ApiService.getOBS(API_ENDPOINT.REPORTS, `/Reports/FetchReportFilters/${_tenantId}/${_reportId}`);
    }

    //Not using below api currently - gst-1923 izenda dependency removal
    static getLanguagesByParentIzendaAsOBS = (_tenantId, _parentIzendaDisplayName) => {
        return ApiService.getOBS(API_ENDPOINT.REPORTS, `/Reports/FetchLanguagesByParentIzenda?tenantId=${_tenantId}&parentIzendaDisplayName=${_parentIzendaDisplayName}`);
    };

    static getLanguagesByReportId = (_tenantId, _reportId, _languages) => {
        return ApiService.getOBS(API_ENDPOINT.REPORTS, `/Reports/FetchLanguagesByReportId?tenantId=${_tenantId}&reportId=${_reportId}&languages=${_languages}`);
    };

    static getReportCompanionDetails = (_tenantId, _parentIzendaDisplayName) => {
        return ApiService.getOBS(API_ENDPOINT.REPORTS, `/Reports/FetchReportCompanionDetails?tenantId=${_tenantId}&parentIzendaDisplayName=${_parentIzendaDisplayName}`);
    };

    static getReportSaveFileTypes = () => {
        return ApiService.getOBS(API_ENDPOINT.REPORTS, `/Reports/FetchReportSaveFileTypes`);
    };
}